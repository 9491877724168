import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { BufProtocol } from "../../../../src/collections/blog/2021/2021-04-24-buf-protocols/BufProtocol.style";
import { Link } from "gatsby";
import Problems from "../../../../src/collections/blog/2021/2021-04-24-buf-protocols/problems.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  BufProtocol,
  Link,
  Problems,
  React
};