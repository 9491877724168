import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import Hamlet from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/Hamlet.webp";
import Graph from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/Graph1.webp";
import Bucket from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/Graph2.webp";
import SMI from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/SMI-demo.webp";
import SMP from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/SMP.webp";
import Abstractions from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/abstractions.webp";
import Journey from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/cloud-native-journey.webp";
import Flowchart from "../../../../src/collections/blog/2021/2021-09-20-service-mesh-specifications-and-why-they-matter/flowchart.webp";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  Hamlet,
  Graph,
  Bucket,
  SMI,
  SMP,
  Abstractions,
  Journey,
  Flowchart,
  Link,
  React
};