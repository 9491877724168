import { Link } from "gatsby";
import Dhruv from "../../../../src/collections/blog/2021/2021-06-4-announcing-summer-interns/intern-dhruv-patel.webp";
import Soham from "../../../../src/collections/blog/2021/2021-06-4-announcing-summer-interns/intern-soham-sonawane.webp";
import Piyush from "../../../../src/collections/blog/2021/2021-06-4-announcing-summer-interns/intern-piyush-singariya.webp";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import * as React from 'react';
export default {
  Link,
  Dhruv,
  Soham,
  Piyush,
  BlogWrapper,
  React
};