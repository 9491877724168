import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import pr211 from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/PR-211.webp";
import pr2112 from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/PR-211-2.webp";
import card1 from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/card1.webp";
import card2 from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/card2.webp";
import arrow from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/arrow.webp";
import gtable from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/graphical-table.webp";
import gcomp from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/graph-compare.webp";
import image21 from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/image.webp";
import comment from "../../../../src/collections/blog/2019/2019-11-15-introducing-comparative-spectrums-to-the-layer5-landscape/comment.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  pr211,
  pr2112,
  card1,
  card2,
  arrow,
  gtable,
  gcomp,
  image21,
  comment,
  React
};