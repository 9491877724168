import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import bugEnvoy from "../../../../src/collections/blog/2022/2022-05-27-debug-envoy-proxy/debug-envoy-proxy.svg";
import DockerExtensionCTA from "../../../../src/sections/Docker-Meshery/docker-extension-CTA";
import Code from "../../../../src/components/CodeBlock";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  bugEnvoy,
  DockerExtensionCTA,
  Code,
  React
};