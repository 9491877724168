import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import BookinfoWith from "../../../../src/collections/resources/articles/istio-at-a-glance/bookinfo-with-proxies.svg";
import BookinfoWithout from "../../../../src/collections/resources/articles/istio-at-a-glance/bookinfo-without-proxies.svg";
import IstioArchitecture from "../../../../src/collections/resources/articles/istio-at-a-glance/istio-architecture.svg";
import * as React from 'react';
export default {
  ResourcesWrapper,
  BookinfoWith,
  BookinfoWithout,
  IstioArchitecture,
  React
};