import { Link } from "gatsby";
import SMP from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/smp-light-text_2.webp";
import cover from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/ieee_bridge_issue3_2021.webp";
import EWtraffic from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/figure-1.webp";
import Workload from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/figure-2.webp";
import Archictures from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/Meshery Architecture - Clients.webp";
import Code from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/code-snippet.webp";
import NetworkFunction from "../../../../src/collections/resources/articles/analyzing-service-mesh-performance/Comparison of different modes of delivery of service mesh network functions.webp";
import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import * as React from 'react';
export default {
  Link,
  SMP,
  cover,
  EWtraffic,
  Workload,
  Archictures,
  Code,
  NetworkFunction,
  ResourcesWrapper,
  React
};