import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import { Link } from "gatsby";
import CloudNative from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hpe-talk/cloud-native-management.webp";
import CloudNativeIdentity from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hpe-talk/cloud-native-identity.webp";
import dde from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hpe-talk/dde.webp";
import designer from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hpe-talk/Designer.webp";
import visualizer from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hpe-talk/Visualizer.webp";
import meshmapInDocker from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hpe-talk/meshmap-docker-extension-for-meshery.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  Link,
  CloudNative,
  CloudNativeIdentity,
  dde,
  designer,
  visualizer,
  meshmapInDocker,
  React
};