import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Switcher from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/context-switcher.webp";
import Deploy from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/deploy-modal.webp";
import Cluster2 from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/meshmap-cluster2.webp";
import Cluster1 from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/meshmap-cluster1.webp";
import Settings from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/settings.webp";
import Cluster from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/cluster-mgmt.webp";
import Flush from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/flush-meshsync.webp";
import CoreArch from "../../../../src/collections/blog/2022/2022-07-28-managing-multiple-clusters-with-meshery/meshery-core-architecture.webp";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  Switcher,
  Deploy,
  Cluster2,
  Cluster1,
  Settings,
  Cluster,
  Flush,
  CoreArch,
  Link,
  React
};