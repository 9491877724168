import { Link } from "gatsby";
import Kanvas from "../../../../src/collections/news/2024/2024-11-12-layer5-launches-kanvas-a-collaborative-platform-for-cloud-native-infrastructure/kanvas-icon-color.svg";
import KanvasScreenshot from "../../../../src/collections/news/2024/2024-11-12-layer5-launches-kanvas-a-collaborative-platform-for-cloud-native-infrastructure/layer5-kanvas-designer.webp";
import { NewsWrapper } from "../../../../src/collections/news/News.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import * as React from 'react';
export default {
  Link,
  Kanvas,
  KanvasScreenshot,
  NewsWrapper,
  Blockquote,
  BlockquoteAlt,
  React
};