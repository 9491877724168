import { Link } from "gatsby";
import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import Stakeholders from "../../../../src/collections/resources/articles/microservices-based-application-delivery/figure1.webp";
import Graph from "../../../../src/collections/resources/articles/microservices-based-application-delivery/citrix-architectures-for-kubernetes-environments.svg";
import TwoTier from "../../../../src/collections/resources/articles/microservices-based-application-delivery/citrix-two-tier-ingress.svg";
import Unified from "../../../../src/collections/resources/articles/microservices-based-application-delivery/citrix-unified-ingress.svg";
import Servicemesh from "../../../../src/collections/resources/articles/microservices-based-application-delivery/citrix-service-mesh.svg";
import Servicemeshlite from "../../../../src/collections/resources/articles/microservices-based-application-delivery/citrix-service-mesh-lite.svg";
import Comparison from "../../../../src/collections/resources/articles/microservices-based-application-delivery/citrix-oss-integration-categories.svg";
import * as React from 'react';
export default {
  Link,
  ResourcesWrapper,
  Stakeholders,
  Graph,
  TwoTier,
  Unified,
  Servicemesh,
  Servicemeshlite,
  Comparison,
  React
};