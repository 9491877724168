import { Link } from "gatsby";
import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import Communication from "../../../../src/collections/resources/articles/value-of-a-service-mesh/figure4.webp";
import Timeouts from "../../../../src/collections/resources/articles/value-of-a-service-mesh/figure3.webp";
import Metrics from "../../../../src/collections/resources/articles/value-of-a-service-mesh/figure2.webp";
import Mixer from "../../../../src/collections/resources/articles/value-of-a-service-mesh/figure1.webp";
import * as React from 'react';
export default {
  Link,
  ResourcesWrapper,
  Communication,
  Timeouts,
  Metrics,
  Mixer,
  React
};