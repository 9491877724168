import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Before from "../../../../src/collections/blog/2023/08-23-importance-of-github-stars/before.png";
import After from "../../../../src/collections/blog/2023/08-23-importance-of-github-stars/after.png";
import Five from "../../../../src/collections/blog/2023/08-23-importance-of-github-stars/five-image.png";
import * as React from 'react';
export default {
  BlogWrapper,
  Before,
  After,
  Five,
  React
};