import { NewsWrapper } from "../../../../src/collections/news/News.style.js";
import MeshMap from "../../../../src/collections/news/2021/2021-10-13-cncf-adopts-meshery/MeshMap.png";
import Management from "../../../../src/collections/news/2021/2021-10-13-cncf-adopts-meshery/configuration-management.png";
import Meshery from "../../../../src/assets/images/meshery/full-logo/meshery-logo-light-text-side.svg";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  NewsWrapper,
  MeshMap,
  Management,
  Meshery,
  Link,
  React
};