import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import comparativeCost from "../../../../src/collections/blog/2024/05-12-cost-of-observability/comparative-cost-of-observability-signals.png";
import Blockquote from "../../../../src/reusecore/Blockquote";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  comparativeCost,
  Blockquote,
  React
};