import { Link } from "gatsby";
import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import Planes from "../../../../src/collections/resources/articles/service-mesh-architectures-and-components/figure1.webp";
import Topology from "../../../../src/collections/resources/articles/service-mesh-architectures-and-components/figure2.webp";
import Architecture from "../../../../src/collections/resources/articles/service-mesh-architectures-and-components/figure3.webp";
import Meshery from "../../../../src/collections/resources/articles/service-mesh-architectures-and-components/figure4.webp";
import * as React from 'react';
export default {
  Link,
  ResourcesWrapper,
  Planes,
  Topology,
  Architecture,
  Meshery,
  React
};