import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import Button from "../../../../src/reusecore/Button";
import DockerLogo from "../../../../src/assets/images/partners/docker.svg";
import dockercompose_logo from "../../../../src/assets/images/dockercompose-logo/dockercompose-logo.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  Button,
  DockerLogo,
  dockercompose_logo,
  React
};