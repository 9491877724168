import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import picture1 from "../../../../src/collections/resources/articles/kubernetes/management-of-kubernetes/meshery-core-architecture.webp";
import picture2 from "../../../../src/collections/resources/articles/kubernetes/management-of-kubernetes/settings.webp";
import picture3 from "../../../../src/collections/resources/articles/kubernetes/management-of-kubernetes/context-switcher.webp";
import ClusterImg from "../../../../src/collections/resources/articles/kubernetes/management-of-kubernetes/multi-cluster-kubernetes-management-with-meshery.webp";
import * as React from 'react';
export default {
  ResourcesWrapper,
  picture1,
  picture2,
  picture3,
  ClusterImg,
  React
};