import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import awsappmesh from "../../../../src/assets/images/service-mesh-icons/aws-app-mesh.webp";
import consul from "../../../../src/assets/images/service-mesh-icons/consul.svg";
import istio from "../../../../src/assets/images/service-mesh-icons/istio.svg";
import linkerd from "../../../../src/assets/images/service-mesh-icons/linkerd.svg";
import maesh from "../../../../src/assets/images/service-mesh-icons/maesh.webp";
import nsm from "../../../../src/assets/images/service-mesh-icons/nsm.svg";
import octarine from "../../../../src/assets/images/service-mesh-icons/octarine.svg";
import kuma from "../../../../src/assets/images/service-mesh-icons/kuma.svg";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  awsappmesh,
  consul,
  istio,
  linkerd,
  maesh,
  nsm,
  octarine,
  kuma,
  Link,
  React
};