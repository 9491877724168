import { Link } from "gatsby";
import { EventWrapper } from "../../../../src/collections/events/Event.style.js";
import hacktoberfestprepslides from "../../../../src/collections/events/2023/hacktoberfest-2023-docker-ext/hacktoberfest-prep-easing-into-cncf-open-source-projects.pdf";
import hacktoberfestBadge from "../../../../src/collections/events/2023/hacktoberfest-2023-docker-ext/hacktoberfest-contributor.png";
import dockerExtension from "../../../../src/collections/events/2023/hacktoberfest-2023-docker-ext/docker-extension-meshery.svg";
import manageWithMeshery from "../../../../src/collections/events/2023/hacktoberfest-2023-docker-ext/docker-extension-meshery.svg";
import * as React from 'react';
export default {
  Link,
  EventWrapper,
  hacktoberfestprepslides,
  hacktoberfestBadge,
  dockerExtension,
  manageWithMeshery,
  React
};