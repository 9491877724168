import { BookWrapper } from "../../../../src/collections/service-mesh-books/Book.style.js";
import service_mesh_ent_book from "../../../../src/collections/service-mesh-books/the-enterprise-path-to-service-mesh-architectures/The-Enterprise-Path-to-Service-Mesh-Architectures.webp";
import Button from "../../../../src/reusecore/Button";
import CommonForm from "../../../../src/components/CommonForm";
import * as React from 'react';
export default {
  BookWrapper,
  service_mesh_ent_book,
  Button,
  CommonForm,
  React
};