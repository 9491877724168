import { Link } from "gatsby";
import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import Working from "../../../../src/collections/resources/articles/istio-ingress-gateway-in-kubernetes/Istio Ingress Gateway.webp";
import Rep from "../../../../src/collections/resources/articles/istio-ingress-gateway-in-kubernetes/Istio Ingress Gateway No Title.webp";
import * as React from 'react';
export default {
  Link,
  ResourcesWrapper,
  Working,
  Rep,
  React
};