import { Link } from "gatsby";
import Ruth from "../../../../src/collections/blog/2020/2020-09-25-announcing-meshmates/meshmate-ruth-ikegah.webp";
import Kelechi from "../../../../src/collections/blog/2020/2020-09-25-announcing-meshmates/meshmate-kelechi-precious.webp";
import Tanuj from "../../../../src/collections/blog/2020/2020-09-25-announcing-meshmates/meshmate-tanuj-agarwal.webp";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import * as React from 'react';
export default {
  Link,
  Ruth,
  Kelechi,
  Tanuj,
  BlogWrapper,
  React
};