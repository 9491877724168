import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import wsldockerstart from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-docker-start.webp";
import wslgrafanalogin from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-grafana-login.webp";
import wslgrafanaloginsuccess from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-grafana-login-success.webp";
import wslgrafanastart from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-grafana-start.webp";
import wslk3dstart from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-k3d-start.webp";
import wslmesherycomplete from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-meshery-complete.webp";
import wslmesherylogin from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-meshery-login.webp";
import wslmesheryloginsuccess from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-meshery-login-success.webp";
import wslmesherystart from "../../../../src/collections/blog/2019/2019-07-09-Meshery-start-feat-WSL2-K3d/wsl-meshery-start.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  wsldockerstart,
  wslgrafanalogin,
  wslgrafanaloginsuccess,
  wslgrafanastart,
  wslk3dstart,
  wslmesherycomplete,
  wslmesherylogin,
  wslmesheryloginsuccess,
  wslmesherystart,
  React
};