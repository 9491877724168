import { Link } from "gatsby";
import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import DevOps from "../../../../src/collections/resources/articles/devops-adoption/devops-adoption-choosing-the-right-metrics.pdf";
import DevOpsAdoption from "../../../../src/collections/resources/articles/devops-adoption/devops-adoption.webp";
import * as React from 'react';
export default {
  Link,
  ResourcesWrapper,
  DevOps,
  DevOpsAdoption,
  React
};