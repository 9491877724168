import { Link } from "gatsby";
import Nithish from "../../../../src/collections/blog/2021/2021-09-01-announcing-fall-interns/nithish-karthik.webp";
import Ashish from "../../../../src/collections/blog/2021/2021-09-01-announcing-fall-interns/ashish-tiwari.webp";
import Rudraksh from "../../../../src/collections/blog/2021/2021-09-01-announcing-fall-interns/rudraksh-pareek.webp";
import Bariq from "../../../../src/collections/blog/2021/2021-09-01-announcing-fall-interns/bariq-nurlis.webp";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import * as React from 'react';
export default {
  Link,
  Nithish,
  Ashish,
  Rudraksh,
  Bariq,
  BlogWrapper,
  React
};