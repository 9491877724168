import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import MesheryOperatorShot from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/meshery-operator-v0.5.0.webp";
import MesheryOperator from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/meshery-operator-dark.svg";
import MeshSync from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/meshsync.svg";
import MesheryDB from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/meshery-database.svg";
import MesheryAdapterLibrary from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/meshery-adapter-library.svg";
import Traefik from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/traefik-mesh.svg";
import MesheryExtensibility from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/meshery-extensibility.svg";
import NGINXSM from "../../../../src/collections/blog/2021/2021-04-19-announcing-meshery-v0.5/nginx-service-mesh.svg";
import layer5Logo from "../../../../src/assets/images/layer5/layer5-only/svg/layer5-no-trim.svg";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  MesheryOperatorShot,
  MesheryOperator,
  MeshSync,
  MesheryDB,
  MesheryAdapterLibrary,
  Traefik,
  MesheryExtensibility,
  NGINXSM,
  layer5Logo,
  React
};