import { ChapterStyle } from "../../../../src/components/Learn-Components/Chapters-Style/chapters.style.js";
import Octopus from "../../../../src/assets/images/learn-layer5/linkerd/octopus.webp";
import WebTop from "../../../../src/assets/images/learn-layer5/linkerd/webtop.webp";
import WebTap from "../../../../src/assets/images/learn-layer5/linkerd/webtap.webp";
import * as React from 'react';
export default {
  ChapterStyle,
  Octopus,
  WebTop,
  WebTap,
  React
};