import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import listioLayer5 from "../../../../src/collections/blog/2020/2020-07-18-service-mesh-patterns-for-multitenancy/layer5-and-istio.webp";
import img1 from "../../../../src/collections/blog/2020/2020-07-18-service-mesh-patterns-for-multitenancy/image1.webp";
import img2 from "../../../../src/collections/blog/2020/2020-07-18-service-mesh-patterns-for-multitenancy/image2.webp";
import img3 from "../../../../src/collections/blog/2020/2020-07-18-service-mesh-patterns-for-multitenancy/image3.webp";
import img4 from "../../../../src/collections/blog/2020/2020-07-18-service-mesh-patterns-for-multitenancy/image4.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  listioLayer5,
  img1,
  img2,
  img3,
  img4,
  React
};