import { Link } from "gatsby";
import Sandy from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/sandySCA.webp";
import Abiola from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/abiolaSCA.webp";
import Cynthia from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/cynthiaSCA.webp";
import Elizabeth from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/elizabethSCA.webp";
import Blossom from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/blossomSCA.webp";
import Joy from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/joySCA.webp";
import Naimat from "../../../../src/collections/blog/2021/2021-06-25-she-code-africa-contributhon-boot-camp/naimatSCA.webp";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import * as React from 'react';
export default {
  Link,
  Sandy,
  Abiola,
  Cynthia,
  Elizabeth,
  Blossom,
  Joy,
  Naimat,
  BlogWrapper,
  React
};