import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import kubecon1 from "../../../../src/collections/blog/2020/2020-11-17-layer5-at-kubecon-2020/kubecon1.webp";
import kubecon2 from "../../../../src/collections/blog/2020/2020-11-17-layer5-at-kubecon-2020/kubecon2.webp";
import kubecon3 from "../../../../src/collections/blog/2020/2020-11-17-layer5-at-kubecon-2020/kubecon3.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  kubecon1,
  kubecon2,
  kubecon3,
  React
};