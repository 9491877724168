import { ProjectWrapper } from "../../../../src/collections/projects/Project.style.js";
import { Container, Row, Col } from "../../../../src/reusecore/Layout";
import smdp1 from "../../../../src/collections/projects/distributed-performance-management/distributed-performance_green.svg";
import smdp2 from "../../../../src/collections/projects/distributed-performance-management/envoy-stacked-color.svg";
import * as React from 'react';
export default {
  ProjectWrapper,
  Container,
  Row,
  Col,
  smdp1,
  smdp2,
  React
};