import { Link } from "gatsby";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import aadityaFirstPr from "../../../../src/collections/blog/2022/2022-07-06-aaditya-gsoc-blogpost/aaditya-first-pr.webp";
import aadityaPresenting from "../../../../src/collections/blog/2022/2022-07-06-aaditya-gsoc-blogpost/aaditya-presenting.webp";
import * as React from 'react';
export default {
  Link,
  BlogWrapper,
  aadityaFirstPr,
  aadityaPresenting,
  React
};