import { Link } from "gatsby";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import token from "../../../../src/collections/blog/2021/2021-10-01-pipelining-service-mesh-specifications/download-token.webp";
import perfdashboard from "../../../../src/collections/blog/2021/2021-10-01-pipelining-service-mesh-specifications/service-mesh-performance-profile-test-results.webp";
import smidashboard from "../../../../src/collections/blog/2021/2021-10-01-pipelining-service-mesh-specifications/smi-conformance-result.webp";
import smpLogo from "../../../../src/assets/images/service-mesh-performance/horizontal/smp-dark-text-side.svg";
import smiLogo from "../../../../src/assets/images/service-mesh-icons/service-mesh-interface/horizontal-stackedtext/color/servicemeshinterface-horizontal-stackedtext-color.svg";
import githubBlack from "../../../../src/assets/images/socialIcons/github_black.svg";
import * as React from 'react';
export default {
  Link,
  BlogWrapper,
  Blockquote,
  token,
  perfdashboard,
  smidashboard,
  smpLogo,
  smiLogo,
  githubBlack,
  React
};