import { Link } from "gatsby";
import { NewsWrapper } from "../../../../src/collections/news/News.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import dockerextension from "../../../../src/collections/news/2022/2022-05-10-layer5-joins-docker-extension-program-bringing-kubernetes-and-service-mesh-management-to-docker-with-meshery/docker-extension-meshery.webp";
import meshmapextension from "../../../../src/collections/news/2022/2022-05-10-layer5-joins-docker-extension-program-bringing-kubernetes-and-service-mesh-management-to-docker-with-meshery/meshmap-docker-extension-for-meshery.webp";
import * as React from 'react';
export default {
  Link,
  NewsWrapper,
  Blockquote,
  BlockquoteAlt,
  dockerextension,
  meshmapextension,
  React
};