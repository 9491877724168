import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import mesheryLogo from "../../../../src/assets/images/meshery/icon-only/meshery-logo-light.svg";
import hacktoberfestEvent from "../../../../src/collections/blog/2023/09-28-hacktoberfest-2023/layer5-event-hacktoberfest2023.png";
import cloudNativeHacktoberfest from "../../../../src/collections/blog/2023/09-28-hacktoberfest-2023/hacktoberfest-2023-with-layer5.png";
import hacktoberfestBadge from "../../../../src/collections/blog/2023/09-28-hacktoberfest-2023/hacktoberfest-contributor.png";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  mesheryLogo,
  hacktoberfestEvent,
  cloudNativeHacktoberfest,
  hacktoberfestBadge,
  Blockquote,
  BlockquoteAlt,
  Link,
  React
};