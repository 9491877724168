import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import STAR from "../../../../src/collections/blog/2024/06-21-meshery-5000-star-milestone/star-meshery.png";
import STATS from "../../../../src/collections/blog/2024/06-21-meshery-5000-star-milestone/meshery-stats.png";
import LFX from "../../../../src/collections/blog/2024/06-21-meshery-5000-star-milestone/lfx-mentorship.png";
import GRAPH from "../../../../src/collections/blog/2024/06-21-meshery-5000-star-milestone/star-history.png";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  STAR,
  STATS,
  LFX,
  GRAPH,
  Blockquote,
  BlockquoteAlt,
  React
};