import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import UnitTest from "../../../../src/collections/blog/2022/2022-01-05-how-to-write-unit-and-integeration-tests-for-mesheryctl/unit-test.webp";
import IntegrationTest from "../../../../src/collections/blog/2022/2022-01-05-how-to-write-unit-and-integeration-tests-for-mesheryctl/integration-test.webp";
import TreeGraph from "../../../../src/collections/blog/2022/2022-01-05-how-to-write-unit-and-integeration-tests-for-mesheryctl/tree-graph.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  UnitTest,
  IntegrationTest,
  TreeGraph,
  React
};