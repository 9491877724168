import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import serviceMesh from "../../../../src/collections/resources/articles/consul-service-mesh/consul-service-mesh.webp";
import agent from "../../../../src/collections/resources/articles/consul-service-mesh/consul-agent-architecture.webp";
import datacenter from "../../../../src/collections/resources/articles/consul-service-mesh/datacenter-architecture.webp";
import proxy from "../../../../src/collections/resources/articles/consul-service-mesh/service-proxy-architecture.webp";
import * as React from 'react';
export default {
  ResourcesWrapper,
  serviceMesh,
  agent,
  datacenter,
  proxy,
  React
};