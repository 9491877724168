import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import { Link } from "gatsby";
import Stars from "../../../../src/collections/blog/2022/2022-01-22-the-story-of-meshery-1000-stars/stars-forks.webp";
import Slack from "../../../../src/collections/blog/2022/2022-01-22-the-story-of-meshery-1000-stars/meshery-star-notifications.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  BlockquoteAlt,
  Link,
  Stars,
  Slack,
  React
};