import { ChapterStyle } from "../../../../src/components/Learn-Components/Chapters-Style/chapters.style.js";
import ImageAppIcon from "../../../../src/assets/images/learn-layer5/istio/istio-addons.webp";
import GraffanaDash from "../../../../src/assets/images/learn-layer5/istio/Grafana_Istio_Dashboard.webp";
import Prometheus from "../../../../src/assets/images/learn-layer5/istio/Prometheus.webp";
import Jaeger from "../../../../src/assets/images/learn-layer5/istio/jaeger copy.webp";
import * as React from 'react';
export default {
  ChapterStyle,
  ImageAppIcon,
  GraffanaDash,
  Prometheus,
  Jaeger,
  React
};