import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import MMoY2021 from "../../../../src/collections/blog/2022/2022-05-20-meshmate-of-the-year-2021-aditya-chatterjee/meshmate-of-the-year-2021.svg";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import { Link } from "gatsby";
import graduateLFX from "../../../../src/collections/blog/2022/2022-05-20-meshmate-of-the-year-2021-aditya-chatterjee/aditya-chatterjee-graduates-lfx-meshery-program.webp";
import MeshMate from "../../../../src/assets/images/meshmate/meshmate-stack.svg";
import MMOYAditya from "../../../../src/collections/blog/2022/2022-05-20-meshmate-of-the-year-2021-aditya-chatterjee/MeshMate-of-the-Year-2021-Aditya-Chatterjee.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  MMoY2021,
  Blockquote,
  BlockquoteAlt,
  Link,
  graduateLFX,
  MeshMate,
  MMOYAditya,
  React
};