import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import pic1 from "../../../../src/collections/blog/2020/2020-07-16-growing-as-an-open-source-contributor-with-layer5/pic1.webp";
import pic2 from "../../../../src/collections/blog/2020/2020-07-16-growing-as-an-open-source-contributor-with-layer5/pic2.jpeg";
import pic3 from "../../../../src/collections/blog/2020/2020-07-16-growing-as-an-open-source-contributor-with-layer5/pic3.webp";
import pic4 from "../../../../src/collections/blog/2020/2020-07-16-growing-as-an-open-source-contributor-with-layer5/pic4.webp";
import pic5 from "../../../../src/collections/blog/2020/2020-07-16-growing-as-an-open-source-contributor-with-layer5/pic5.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  React
};