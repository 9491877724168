import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import Button from "../../../../src/reusecore/Button";
import FiveInstructions from "../../../../src/collections/blog/2024/03-27-models-designs-legos/five-instructions.png";
import FiveLegos from "../../../../src/collections/blog/2024/03-27-models-designs-legos/five-legos.png";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  Button,
  FiveInstructions,
  FiveLegos,
  React
};