import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import img from "../../../../src/collections/blog/2021/2021-04-16-meet-the-maintainer-jash-patel/jash-patel-layer5-maintainer.webp";
import { MeetTheMaintainer } from "../../../../src/collections/blog/MeetTheMaintainer.style.js";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  img,
  MeetTheMaintainer,
  Link,
  React
};