import { Link } from "gatsby";
import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import smilogo from "../../../../src/collections/blog/2020/2020-06-25-SMI-conformance-testing-with-meshery/smi-logo.webp";
import checklist from "../../../../src/collections/blog/2020/2020-06-25-SMI-conformance-testing-with-meshery/checklist.svg";
import * as React from 'react';
export default {
  Link,
  BlogWrapper,
  smilogo,
  checklist,
  React
};