import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import MeshManager from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/mesh-manager.webp";
import MesheryClients from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/meshery-clients.webp";
import Mesheryfeatures from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/meshery-features.webp";
import MesheryDeployments from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/meshery-deployment.webp";
import Nighthawk from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/nighthawk.webp";
import Patterns from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/patterns.webp";
import ServiceMeshPatterns from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/service-mesh-patterns.webp";
import Planes from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/planes.webp";
import Mesheryoperatoricon from "../../../../src/assets/images/meshery-operator/meshery-operator.svg";
import MesheryLogo from "../../../../src/assets/images/meshery/icon-only/meshery-logo-light.svg";
import NighthawkIcon from "../../../../src/assets/images/nighthawk/icon-only/SVG/nighthawk-logo.svg";
import PatternsLogo from "../../../../src/collections/blog/2021/2021-10-09-an-introduction-to-meshery/patterns-logo.webp";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  MeshManager,
  MesheryClients,
  Mesheryfeatures,
  MesheryDeployments,
  Nighthawk,
  Patterns,
  ServiceMeshPatterns,
  Planes,
  Mesheryoperatoricon,
  MesheryLogo,
  NighthawkIcon,
  PatternsLogo,
  Link,
  React
};