import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import oldDesign from "../../../../src/collections/blog/2022/2022-06-09-evolution-of-mesheryctl-ref/initial-design.webp";
import newDesign from "../../../../src/collections/blog/2022/2022-06-09-evolution-of-mesheryctl-ref/mesheryctl-docs.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  oldDesign,
  newDesign,
  React
};